const statusManagementKpi = [
    {
        name: 'Cần nhập thành phẩm',
        value: 0,
    },
    {
        name: 'Cần chấm',
        value: 1,
    },
    {
        name: 'Đã chấm',
        value: 2,
    },
]

const pointOptionKpi = [
    {
        name: '0%',
        value: 0,
    },
    {
        name: '30%',
        value: 30,
    },
    {
        name: '60%',
        value: 60,
    },
    {
        name: '90%',
        value: 90,
    },
    {
        name: '100%',
        value: 100,
    },
]

const stageOption = [
    {
        name: 'Đang tích lũy',
        value: 0,
    },
    {
        name: 'Có hiệu lực',
        value: 1,
    },
    {
        name: 'Được chuyển nhượng/Bán',
        value: 2,
    },
    {
        name: 'Đã chuyển nhượng/Bán',
        value: 3,
    },
]

const resultPointWeekly = [
    {
        name: 'Không đạt',
        value: 0,
    },
    {
        name: 'Đạt',
        value: 1,
    },
]

const statusProduction = [
    {
        name: 'Chờ lớp',
        value: 1,
    },
    {
        name: 'Đang học khóa dưới',
        value: 2,
    },
    {
        name: 'Đang xử lý đầu ra',
        value: 3,
    },
]

const category_chamsoc_define = {
    1: 'Nghỉ học 2 buổi',
    2: 'Điểm kiểm tra thấp',
    3: 'Không làm bài',
    4: 'Chua đóng tiền học',
    5: 'Đã đóng tiền học',
    6: 'Feedback thấp',
    7: 'Nghỉ học 1 buổi',
    8: 'Vắng có phép',
    9: 'Hướng dẫn app cho phụ huynh',
    10: 'Kết quả BTVN không tốt',
    11: 'Kết quả BTVN không tốt',
    13: 'Nhắc nhở bảo hành',
    14: 'Nghỉ 1 buổi không phép',
    15: 'Nghỉ buổi khai giảng',
    16: 'Chăm sóc học viên giữa khóa'
}

const listReasonAbsent = [
    {
        'id': 1,
        'value': 'Nghỉ hẳn lớp học',
    },
    {
        'id': 2,
        'value': 'Bận việc cá nhân',
    },
    {
        'id': 3,
        'value': 'Vấn đề ve sức khỏe',
    },
    {
        'id': 4,
        'value': 'Quân sự/ thực tập',
    },
    {
        'id': 5,
        'value': 'Khác',
    },
    {
        id: 25,
        value: '2 buổi không làm BTVN',
    },
    {
        id: 26,
        value: 'Nghỉ học 2 buổi',
    },
    {
        id: 27,
        value: 'Nghỉ 1 buổi + Không làm BTVN 1 buổi.',
    },
]

const problems = [
    {
        id: 1,
        name: 'Feedback Giáo viên',
    },
    {
        id: 19,
        name: 'Feedback trợ giảng',
    },
    {
        id: 3,
        name: 'Cơ sở vật chất',
    },
    {
        id: 20,
        name: 'Xin thêm tài liệu',
    }, {
        id: 21,
        name: 'Feedback App BTVN',
    },
    {
        id: 22,
        name: 'Yêu cầu bổ trợ',
    },
    {
        id: 23,
        name: 'Khác',
    },
]
const listReasonOffLesson = [
    {
        id: 9,
        name: 'Bận việc cá nhân',
    },
    {
        id: 10,
        name: 'Trùng lịch học',
    },
    {
        id: 11,
        name: 'Nghỉ ốm',
    },
    {
        id: 12,
        name: 'Du lịch',
    }, {
        id: 13,
        name: 'Quân sự/ Thực tập',
    },
    {
        id: 14,
        name: 'Bảo lưu',
    },
    {
        id: 24,
        name: 'Nghỉ hẳn',
    },
    {
        id: 23,
        name: 'Khác',
    },
]

const statusReportError = [
    {
        id: 1,
        name: 'Chưa xử lý'
    },
    {
        id: 2,
        name: 'Đã xử lý'
    },
    {
        id: 3,
        name: 'Huỷ'
    },
]

const statusDefine = {
    0: 'Hủy',
    1: 'Chờ xử lý',
    2: 'Đã nhập giải pháp',
    3: 'Đã hoàn thành'
}

const templateCertificates = [
    {
        id: 1,
        templateName: 'IM-Basic',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 378,
        fontSizeDate: 20,
        topDate: 675,
        leftDate: 130,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-IM-basic.jpg',
        fontStyle: 'normal'
    },
    {
        id: 2,
        templateName: 'IM-Pre',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 380,
        fontSizeDate: 20,
        topDate: 605,
        leftDate: 157,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-IM-Pre.jpg',
        fontStyle: 'normal'
    },
    {
        id: 3,
        templateName: 'IM-3.5-4.5',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 508,
        fontSizeDate: 20,
        topDate: 700,
        leftDate: 105,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-IM-3.5-4.5.jpg',
        fontStyle: 'normal'
    },
    {
        id: 4,
        templateName: 'IM-4.5-5.5',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 470,
        fontSizeDate: 20,
        topDate: 705,
        leftDate: 132,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-IM-4.5-5.5.jpg',
        fontStyle: 'normal'
    },
    {
        id: 5,
        templateName: 'IM-5.5-6.5',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 406,
        fontSizeDate: 20,
        topDate: 720,
        leftDate: 105,
        width: 595,
        height: 842,
        color: 2,
        image: 'certificate-IM-5.5-6.5.jpg',
        fontStyle: 'normal'
    },
    {
        id: 6,
        templateName: 'IM-Outstanding',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 440,
        fontSizeDate: 20,
        topDate: 675,
        leftDate: 128,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-IM-outstanding.jpg',
        fontStyle: 'normal'
    },
    {
        id: 7,
        templateName: 'IM-Excellent',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 470,
        fontSizeDate: 20,
        topDate: 675,
        leftDate: 138,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-IM-Excellent.jpg',
        fontStyle: 'normal'
    },
    {
        id: 8,
        templateName: 'EC-Basic',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 390,
        fontSizeDate: 20,
        topDate: 660,
        leftDate: 97,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-EC-basic.jpg',
        fontStyle: 'normal'
    },
    {
        id: 9,
        templateName: 'EC-Pre',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 440,
        fontSizeDate: 20,
        topDate: 640,
        leftDate: 110,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-EC-Pre.jpg',
        fontStyle: 'normal'
    },
    {
        id: 10,
        templateName: 'EC-GTCB',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 440,
        fontSizeDate: 20,
        topDate: 640,
        leftDate: 110,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-EC-GTCB.jpg',
        fontStyle: 'normal'
    },
    {
        id: 11,
        templateName: 'EC-4.5-5.5',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 450,
        fontSizeDate: 20,
        topDate: 638,
        leftDate: 154,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-EC-4.5-5.5.jpg',
        fontStyle: 'normal'
    },
    {
        id: 12,
        templateName: 'EC-5.5-6.5',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 445,
        fontSizeDate: 20,
        topDate: 654,
        leftDate: 138,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-EC-55-65.jpg',
        fontStyle: 'normal'
    },
    {
        id: 13,
        templateName: 'EC-Outstanding',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 415,
        fontSizeDate: 20,
        topDate: 629,
        leftDate: 130,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-EC-outstanding.jpg',
        fontStyle: 'normal'
    },
    {
        id: 14,
        templateName: 'EC-Excellent',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 400,
        fontSizeDate: 20,
        topDate: 630,
        leftDate: 118,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-EC-Excellent.jpg',
        fontStyle: 'normal'
    },
    {
        id: 15,
        templateName: 'EC-Foundation',
        type: 'portrait',
        fontName: 'SVN-Flamingo-script',
        fontFile: 'SVN-Flamingo-script.ttf',
        fontSizeName: 50,
        topName: 395,
        fontSizeDate: 20,
        topDate: 685,
        leftDate: 135,
        width: 595,
        height: 842,
        color: 1,
        image: 'certificate-EC-foundation.jpg',
        fontStyle: 'normal'
    },
]

export {
    problems,
    pointOptionKpi,
    resultPointWeekly,
    statusManagementKpi,
    stageOption,
    statusProduction,
    category_chamsoc_define,
    listReasonAbsent,
    listReasonOffLesson,
    statusReportError,
    statusDefine,
    templateCertificates,
}